<template lang="pug">
v-dialog(v-model="dialog", max-width="600")
  template(v-slot:activator="{ on }")
    v-btn.mx-2(color="error", x-small, v-on="on", outlined, @click="load") Refund
  v-card
    v-card-title
      span Refund & Change Items
    v-card-text
      p You can use this to refund or change items in the invoice. Only works if the changed items is less expensive.
      v-data-table(
        v-model="selected",
        :items="invoice.items",
        :headers="headers",
        show-select,
        item-key="_id",
        mobile-breakpoint="0"
      )
      .subtitle-2.mt-4 Removed Amount: {{ removedTotal | currency }}
      v-data-table(
        v-model="newItems",
        :items="service_items",
        :headers="serviceItemheaders",
        show-select,
        item-key="_id",
        mobile-breakpoint="0"
      )
      .subtitle-2.mt-4 Added Amount: {{ newTotal | currency }}
      .subtitle-2.mt-4 Total Refund: {{ total | currency }}
    v-card-actions
      v-spacer
      v-btn(@click="handleRefund", color="error", :loading="loading") Refund
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: ["invoice"],
  data() {
    return {
      headers: [
        { text: "Item", value: "name" },
        { text: "Price", value: "amount" },
        { text: "Quantity", value: "quantity", align: "end" },
      ],
      serviceItemheaders: [
        { text: "Name", value: "name" },
        { text: "US Price", value: "us_price", align: "end" },
        { text: "CA Price", value: "ca_price", align: "end" },
      ],
      dialog: false,
      selected: [],
      removeIndices: [],
      newItems: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["service_items"]),
    removedTotal() {
      return this.selected.reduce((a, b) => a + b.amount * b.quantity, 0);
    },
    newTotal() {
      return this.newItems?.reduce((a, b) => a + b.us_price * 1, 0) || 0;
    },
    total() {
      return this.removedTotal - this.newTotal;
    },
  },
  methods: {
    ...mapActions(["updateInvoice", "setBizServiceItems"]),
    async load() {
      this.selected = [];
      this.removeIndices = [];
      this.newItems = [];
      this.dialog = true;
      const params = { criteria: {} };
      const result = await this.$api.service.item.list(params);
      this.setBizServiceItems(result);
    },
    handleRefund() {
      // get removedIndices from selected
      this.removeIndices = this.selected
        .map((item) => {
          const index = this.invoice.items.findIndex((i) => i._id === item._id);
          if (index === -1) return null;
          return index;
        })
        .filter((i) => i !== null);
      if (!this.selected?.length) return this.$toast.error("No item selected");
      confirm("Do you want to refund selected items?") && this.refund();
    },
    async refund() {
      const params = {
        invoiceId: this.invoice._id,
        removeIndices: this.removeIndices,
        newItems: this.newItems.map((item) => ({
          _id: item._id,
          name: item.name,
          detail: item.detail,
          amount: item.us_price,
          quantity: 1,
        })),
      };
      this.loading = true;
      try {
        const result = await this.$api.invoice.custom("refund", params);
        this.dialog = false;
        this.updateInvoice(result);
        this.$toast.success("Refund was successful");
      } catch (e) {
        this.$toast.error(e.response?.data || e.message);
      }
      this.loading = false;
    },
  },
};
</script>
